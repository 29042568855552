/*
   @page table list page (merchant list)
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUIDataTable from "mui-datatables";
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';

/*
  @const Page styles
*/
const styles = {
  paper: { maxWidth: "calc(100% - 25%)", width: "calc(100% - 25%)" },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function merchant list page logic
*/
const useStyles = makeStyles(styles);
const doLogin = (merchantId, locationId, isActive) => {
  localStorage.setItem('is_active', isActive);
  const data = { merchant_id: merchantId };
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
  axios.post(window.apiUrl + 'login_as_merchant', data, {
    headers: headers
  }).then(response => {
    if (response.data.token) {
      const currentWebUrl = window.location.href;
      var localHostMode = "OFF";
      if (currentWebUrl == "https://web17.magicpaypos.com/" || currentWebUrl == "https://web17.magicpaypos.com" || currentWebUrl == "https://web17.magicpaypos.com/login" || currentWebUrl == "https://web17.magicpaypos.com/login/") {
        localHostMode = "ON";
      } else {
        if (currentWebUrl == "http://localhost:3000/admin/table" || currentWebUrl == "http://localhost:3000/admin/table/") {
          localHostMode = "ON";
        } else if (currentWebUrl == "https://devapp.magicpaypos.com/admin/table" || currentWebUrl == "https://devapp.magicpaypos.com/admin/table/") {
          localHostMode = "ON";
        } else if (currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table" || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table/") {
          localHostMode = "ON";
        }
      }


      if (response.data.same_version === "0" && localHostMode == "OFF") {

        window.open(response.data.react_url + 'login/?arg1=' + btoa(localStorage.getItem('arg1')) + '&arg2=' + btoa(localStorage.getItem('arg2')) + '&arg3=' + btoa(merchantId), '_blank');
      }
      else {
        console.log("in elseeeee");
        localStorage.setItem('merchantToken', response.data.token);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('parent_id', merchantId);
        localStorage.setItem('location_id', locationId);
        console.log("asdfadfadfasdf");
        window.open('/dashboard', '_blank');

      }

    }
  }).catch(error => {
    return error;
  });

}

const options = {
  filterType: 'checkbox',
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  searchPlaceholder: 'Search using MID, Business Name, Email',
  searchOpen: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  searchAlwaysOpen: true,

};
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [bodyMsg, setBodyMsg] = useState("");
 

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  


  /*
    @Function get merchant list
  */
  const sendResetPasswordEmail = () => {
    setBodyMsg("");
    const email = localStorage.getItem('admin_email')
    const data = {email:email};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'forgot_password', data, {
      headers: headers
    }).then(response => {    
      setTableData([]);
      setSuccess(true);
      setSuccessMsg("Reset password link has been sent to your email.");
      setBodyMsg("Reset password link has been sent to your email.");
    }).catch(error => { 
      setTableData([]);     
      setError(true);
      setErrorMsg("Some Error Occurred!");
      setBodyMsg("Some Error Occurred!");
      return error;
    });
  }


  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
    setSuccessMsg("");
    setErrorMsg("");
  };

 
  useEffect(() => {
    sendResetPasswordEmail();
  }, []);

  
  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* 
            Display error message
          */}          
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Rest Password</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
  <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Reset Password</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>            
          <center>          
              <h3>{bodyMsg}  </h3>
            </center>     
          </CardBody>
        </Card>

      </GridItem>
    </GridContainer>
  );
}
