/*
   @page user profile
*/

//Import require libraries
import React, { useEffect, useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import axios from 'axios';

import FormControl from '@mui/material/FormControl';
import TextField from '@material-ui/core/TextField';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@mui/material/Pagination';
import CustomTable from "components/Table/Table";
import Helper from "./../../util/Helper";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  datePickerTextBox: {
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    width: '90%',
    height: '45px',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  hiddenContent: { display: 'none', }
};

const useStyles = makeStyles(styles);


export default function UserProfile() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [search_Serial_Number, setSearchSerialNumber] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");

  const [dateRange, setDateRange] = React.useState('');

  const [batchDataType, setBatchDataType] = React.useState('');
  const [batchType, setBatchType] = useState("");

    useEffect(() => {
        getbatchmatchdata();
    }, []);

    const handleSearch = () =>
    { 
        
        var sDate = new Date(startDate);    
        if (endDate === null || endDate === "" || endDate === undefined) {
        setEndDate(new Date());
        var eDate = new Date(new Date());
        } else {
        var eDate = new Date(endDate);
        }
       
        const data = { 'serialNumber': search_Serial_Number,'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10),'batchType':batchType}; //,'fromAmount':fromAmount,'toAmount': toAmount
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        //   var batchMatchData = {};
          axios.post(window.apiUrl + 'batch_match_records', data, {
            headers: headers
          }).then(response => {
             const userArr = [];
            response.data.batchData.forEach((eachBatchData) => {   
                var creditAmount = Helper.viewCurrencyFormat(parseFloat(eachBatchData.credit_amt));   
                var creditAmountFromPax = Helper.viewCurrencyFormat(parseFloat(eachBatchData.credit_amt_from_pax));        
                var debitAmount = Helper.viewCurrencyFormat(parseFloat(eachBatchData.debit_amt));
                var debitAmountFromPax = Helper.viewCurrencyFormat(parseFloat(eachBatchData.debit_amt_from_pax));
                userArr.push([eachBatchData.id,eachBatchData.device_date,eachBatchData.device_time,eachBatchData.credit_cnt,creditAmount,eachBatchData.credit_cnt_from_pax,creditAmountFromPax,eachBatchData.debit_cnt,
                debitAmount,eachBatchData.debit_cnt_from_pax,debitAmountFromPax
                    ]);   
                    // eachBatchData.location_id,
            });
            setTableData(userArr);
        }).catch(error => { 
            return error;
        });
    }


    const getbatchmatchdata = () =>
    { 
        
        var sDate = new Date(startDate);    
        if (endDate === null || endDate === "" || endDate === undefined) {
        setEndDate(new Date());
        var eDate = new Date(new Date());
        } else {
        var eDate = new Date(endDate);
        }
       
        const data = { 'serialNumber': '1850517109','startDate': new Date().toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10) };
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        //   var batchMatchData = {};
          axios.post(window.apiUrl + 'batch_match_records', data, {
            headers: headers
          }).then(response => {
            
             const userArr = [];
             response.data.batchData.forEach((eachBatchData) => {   
                var creditAmount = Helper.viewCurrencyFormat(parseFloat(eachBatchData.credit_amt));   
                var creditAmountFromPax = Helper.viewCurrencyFormat(parseFloat(eachBatchData.credit_amt_from_pax));        
                var debitAmount = Helper.viewCurrencyFormat(parseFloat(eachBatchData.debit_amt));
                var debitAmountFromPax = Helper.viewCurrencyFormat(parseFloat(eachBatchData.debit_amt_from_pax));
                userArr.push([eachBatchData.device_date,eachBatchData.device_time,eachBatchData.credit_cnt,creditAmount,eachBatchData.credit_cnt_from_pax,creditAmountFromPax,eachBatchData.debit_cnt,
                debitAmount,eachBatchData.debit_cnt_from_pax,debitAmountFromPax
                    ]);   
                    // eachBatchData.location_id,
            });
            setTableData(userArr);
            console.log("tableData");
            console.log(tableData);
            console.log("tableData");
        }).catch(error => { 
            return error;
        });
    }

  /*
    @function date filtered data
  */
    const dateChange = (event) => {
      let selected_date_range = event.target.value;
      setDateRange(event.target.value);
      switch (selected_date_range) {
        case 1:
        setStartDate(new Date());
        setEndDate(new Date());
        break;
        case 2: 
        let Yesterday_date = new Date();
        Yesterday_date.setDate(Yesterday_date.getDate() - 1);
        setStartDate(Yesterday_date);
        setEndDate(new Date());
        break;
        case 3: 
        let this_week = new Date();
        this_week.setDate(this_week.getDate() - this_week.getDay());
        setStartDate(this_week);
        setEndDate(new Date());
        break;
        case 4: 
        let last_week_start_date = new Date();
        let last_week_end_date = new Date();
        last_week_start_date.setDate(last_week_start_date.getDate() - last_week_start_date.getDay() - 7);
        last_week_end_date.setDate(last_week_end_date.getDate() - last_week_end_date.getDay() - 1);
        setStartDate(last_week_start_date);
        setEndDate(last_week_end_date);
        break;
        case 5:
        let last_7_day = new Date();
        last_7_day.setDate(last_7_day.getDate() - 6);
        setStartDate(last_7_day);
        setEndDate(new Date());
        break;
        case 6:
        let this_month = new Date();
        setStartDate(new Date(this_month.getFullYear(), this_month.getMonth(), 1));
        setEndDate(new Date());
        break;
        case 7:
        let last_month = new Date();
        setStartDate(new Date(last_month.getFullYear(), last_month.getMonth() - 1, 1));
        setEndDate(new Date(last_month.getFullYear(), last_month.getMonth(), 0));
        break;
        case 8:
          let last_30_day = new Date();
          last_30_day.setDate(last_30_day.getDate() - 29)
          setStartDate(last_30_day);
          setEndDate(new Date());
          break;
        case 9:
          let last_3_month = new Date();
          last_3_month.setMonth(last_3_month.getMonth() - 3)
          setStartDate(last_3_month);
          setEndDate(new Date());
        break;
        case 10:
          let this_year = new Date();                              
          this_year = new Date(this_year.getFullYear(), 0, 1);
          setStartDate(this_year);
          setEndDate(new Date());
        break;
        case 11:
          let lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
          setStartDate(new Date(lastyear.getFullYear(), 0, 1));
          setEndDate(new Date(lastyear.getFullYear(), 11, 31));
        break;
        case 12:
          setEndDate("");
          setStartDate("");
        break;
        default:
        break;
      }
    };

    const batchTypeChange = (event) => {
      let type = event.target.value;
      setBatchDataType(event.target.value);
      switch (type) {
        case 1:
          setBatchType("matched");
        break;
        case 2: 
          setBatchType("not matched");
        break;
        default:
          setBatchType("");
        break;
      }
    };

  /*
    * Const custom style
  */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,
    }),
    control: (provided) => ({ 
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)"
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar-thumb": {
        background: "#80ADFF"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#1769ff"
      }
    }),
  }

  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  if (!tableData) { //Check if device data not come than display loading dialog
    return (
      <GridContainer>
        
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Device List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={Error} autoHideDuration={6000}>
        <Alert severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} >
        <Alert severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
           
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Batch Match</h4>
              </CardHeader>

              <CardBody>
              <GridContainer>
              <GridItem id="gidaterange" xs={12} sm={12} md={3}>
              
                <FormControl style={{ width: "100%",marginTop:"35px" }}>
                  <InputLabel id="demo-simple-select-helper-label">Date Range</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={dateRange}
                    label="Date Range"
                    onChange={dateChange}
                    >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Today</MenuItem>
                    <MenuItem value={2}>Yesterday</MenuItem>
                    <MenuItem value={3}>This Week</MenuItem>
                    <MenuItem value={4}>Last Week</MenuItem>
                    <MenuItem value={5}>Last 7 Days</MenuItem>
                    <MenuItem value={6}>This Month</MenuItem>
                    <MenuItem value={7}>Last Month</MenuItem>
                    <MenuItem value={8}>Last 30 Days</MenuItem>
                    <MenuItem value={9}>Last 3 Months</MenuItem>
                    <MenuItem value={10}>This Year</MenuItem>
                    <MenuItem value={11}>Last Year</MenuItem>
                    <MenuItem value={12}>Custom Date Range</MenuItem>
                  </Select>
                </FormControl>
              
                </GridItem>
                <GridItem xs={12} sm={12} md={3} >
                    <div style={{ height: "25px" }}>&nbsp;</div>
                    <FormControl style={{ width: "100%",marginTop:"8px" }}>
                    {/* Amount to textfield */}
                    <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        label="Serial Number"
                        onChange={(e) => { setSearchSerialNumber(e.target.value)}}                    
                        value={search_Serial_Number}
                        
                    />
                    </FormControl>
                 </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl id="batchMatchStartEndDate" sx={{ m: 1, width: "100%" }}>
                  <span>Start Date</span>
                  <DatePicker className={classes.datePickerTextBox} selected={startDate} onChange={(date) => setStartDate(date)} />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl id="batchMatchStartEndDate" sx={{ m: 1, width: "100%" }}>
                  <span>End Date</span>
                  <DatePicker className={classes.datePickerTextBox} selected={endDate} onChange={(date) => setEndDate(date)} />
                </FormControl>
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={3} >
                    <div style={{ height: "25px" }}>&nbsp;</div>
                    <FormControl style={{ width: "100%",marginTop:"8px" }}>
                  
                    <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        label="From Amount"
                        onChange={(e) => { setFromAmount(e.target.value)}}                    
                        value={fromAmount}
                        
                    />
                    </FormControl>
                 </GridItem>

                 <GridItem xs={12} sm={12} md={3} >
                    <div style={{ height: "25px" }}>&nbsp;</div>
                    <FormControl style={{ width: "100%",marginTop:"8px" }}>
                    
                    <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        label="To Amount"
                        onChange={(e) => { setToAmount(e.target.value)}}                    
                        value={toAmount}
                        
                    />
                    </FormControl>
                 </GridItem> */}
              
              </GridContainer>
              <GridContainer>
              
              <GridItem xs={12} sm={12} md={3}>
              
              
              <FormControl style={{ width: "100%",marginTop:"35px" }}>
                <InputLabel id="demo-simple-select-helper-label">Select Type</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={batchDataType}
                  label="Select Type"
                  onChange={batchTypeChange}
                  >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Matched</MenuItem>
                  <MenuItem value={2}>Not Matched</MenuItem>
                </Select>
              </FormControl>
            
              </GridItem>
              <GridItem xs={12} sm={12} md={3}></GridItem>
              <GridItem xs={12} sm={12} md={3}></GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                <div style={{ height: "25px" }}>&nbsp;</div>
                  <FormControl style={{ width: '100%',marginTop:"3px" }} className={classes.popupHalfWidth} xs={12} sm={12} md={12}>
                    <Button variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} onClick={handleSearch} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>

              </GridItem>
              </GridContainer>
                <Table
                tableHeaderColor="primary"
                // "Location Id",
                tableHead={["Batch ID","Device Date","Device Time", "credit #","Credit Amount","Credit # From Pax", "Credit Amount From Pax","Debit #","Debit Amount","Debit # From Pax","Debit Amount From Pax"]}
                tableData={tableData}
                />
           
              
              
   
               
              </CardBody>

              <CardFooter>
               
              </CardFooter>
            
           
          </Card>


        </GridItem>

      </GridContainer>
    </div>
  );
}
