/*
  @page sales report
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import ChartistGraph from "react-chartist";
import TextField from "@mui/material/TextField";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
// import DateTimePicker from '@mui/lab/DateTimePicker';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "2.6",
    width: "90%",
    color: "#495057",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  reportEachRow: {
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    width: "97%",
    paddingLeft: "3%",
  },
  reportEachRowRightSide: {
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "right",
    width: "97%",
    paddingRight: "3%",
  },
  reportEachRowScrollable: {
    float: "left",
    width: "125px",
  },
  reportEachRowScrollableOne: {
    width: "100% !important",
  },
  reportEachRowScrollableTwo: {
    width: "50% !important",
  },
  reportEachRowScrollableThree: {
    width: "33% !important",
  },
  reportEachRowScrollableFour: {
    width: "25%",
  },
  reportEachRowScrollableFive: {
    width: "20% !important",
  },
  reportEachRowScrollableSix: {
    width: "16.66% !important",
  },
  reportEachRowScrollableSeven: {
    width: "14.28% !important",
  },
  reportEachRowScrollableEight: {
    width: "12.5% !important",
  },
  rowBackgroundColorOne: {
    backgroundColor: "#f2f2f2",
  },
  reportRowLeft: {
    textAlign: "left",
  },
  reportRowRight: {
    textAlign: "right",
  },
  classTextRight: {
    textAlign: "right",
  },
  fontWeightBold: {
    fontWeight: "bold",
    fontSize: "1.125em",
  },
  fontBlueColor: {
    color: "#3271ab",
  },
  borderTopLine: {
    borderTop: "1px solid #CACACA",
  },
  borderBottomLine: {
    borderBottom: "1px solid #CACACA",
  },
  tableleftheader: {
    left: "0px",
    height: "35px",
    lineHeight: "35px",
    textAlign: "left",
    paddingLeft: "5px",
    width: "80px",
    minWidth: "250px",
    overflowX: "hidden",
    backgroundColor: "#e5e5e5",
    position: "sticky",
    borderLeft: "1px solid rgb(202, 202, 202)",
    borderRight: "1px solid rgb(202, 202, 202)",
    borderTop: "1px solid rgb(202, 202, 202)",
    borderBottom: "1px solid rgb(202, 202, 202)",
  },
  tablerightheader: {
    right: "0px",
    height: "35px",
    lineHeight: "35px",
    textAlign: "right",
    paddingRight: "5px",
    paddingLeft: "5px",
    width: "80px",
    minWidth: "250px",
    overflowX: "hidden",
    backgroundColor: "#e5e5e5",
    position: "sticky",
    borderLeft: "1px solid rgb(202, 202, 202)",
    borderRight: "1px solid rgb(202, 202, 202)",
    borderTop: "1px solid rgb(202, 202, 202)",
    borderBottom: "1px solid rgb(202, 202, 202)",
  },
  tablecenterheader: {
    width: "80px",
    height: "35px",
    lineHeight: "35px",
    textAlign: "right",
    paddingRight: "5px",
    paddingLeft: "5px",
    minWidth: "300px",
    backgroundColor: "#e5e5e5",
    borderBottom: "1px solid rgb(202, 202, 202)",
    borderTop: "1px solid rgb(202, 202, 202)",
  },
  buttonSearch: {
    width: "100%",
    backgroundColor: "#ffa726",
    color: "#FFFFFF",
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color: "white !important",
    },
  },
  startDateBtn: {
    width: "100% !important",
    paddingLeft: "1%",
    "& div:nth-child(1)": {
      width: "100% !important",
    },
  },
};

/*
  @const create pie chart
*/
const emailsSubscriptionChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    series: [
      [
        { meta: "value is:", value: 500 },
        { meta: "value is:", value: 200 },
        { meta: "value is:", value: 600 },
        { meta: "value is:", value: 400 },
        { meta: "value is:", value: 1600 },
        { meta: "value is:", value: 300 },
        { meta: "value is:", value: 700 },
        { meta: "value is:", value: 800 },
        { meta: "value is:", value: 900 },
        { meta: "value is:", value: 500 },
        { meta: "value is:", value: 500 },
        { meta: "value is:", value: 800 },
      ],
    ],
  },
  options: {
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
    },
    low: 0,
    high: 5,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
    height: 400,
    plugins: [
      ChartistTooltip({
        appendToBody: true,
      }),
    ],
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
        data.element.attr({
          style: "stroke: #235586; fill:#235586; stroke-width: 30px;",
        });
      }
    },
  },
};
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;
const useStyles = makeStyles(styles);

/*
  @function sales report page logic
*/
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [reportStartDate, setReportStartDate] = React.useState();
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [startTime, setStartTime] = React.useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [endTime, setEndTime] = React.useState(
    new Date().setHours(23, 59, 59, 59)
  );
  const [displayStartDate, setDisplayStartDate] = React.useState("");
  const [displayEndDate, setDisplayEndDate] = React.useState("");
  const [visible, setVisible] = React.useState(true);
  const [visibleSummary, setVisibleSummary] = React.useState(false);
  const [weeklyBtnColor, setWeeklyBtnColor] = React.useState(true);
  const [summaryBtnColor, setSummaryBtnColor] = React.useState(false);
  const [grossTotal, setGrossTotal] = React.useState(0.0);
  const [discountTotal, setDiscountTotal] = React.useState(0.0);
  const [refundTotal, setRefundTotal] = React.useState(0.0);
  const [taxesTotal, setTaxesTotal] = React.useState(0.0);
  const [tipTotal, setTipTotal] = React.useState(0.0);
  const [serviceChargesTotal, setServiceChargesTotal] = React.useState(0.0);
  const [collectedAmtTotal, setCollectedAmtTotal] = React.useState(0.0);
  const [netSaleTotal, setNetSaleTotal] = React.useState(0.0);
  const [totalOrders, setTotalOrder] = React.useState(0);
  const [dateRange, setDateRange] = React.useState("");
  const [salesType, setSalesType] = React.useState("");
  const [showData, setShowData] = React.useState("");
  const [selectEmployee, setEmployee] = React.useState(0);
  const [selectDevice, setDevice] = React.useState(0);
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [reportFilter, setReportFilter] = useState({ emp: 0, device: 0 });
  const [csvData, setCsvData] = useState([]);
  const [chartData, setChartData] = React.useState({
    data: [],
    options: {
      axisX: { showGrid: true },
      axisY: { showGrid: true },
      low: 0,
      high: 5,
      chartPadding: { top: 0, right: 5, bottom: 0, left: 0 },
      height: 400,
      plugins: [ChartistTooltip({ appendToBody: true })],
    },
  });
  const [accessPage, setAccessPage] = useState(1);

  useEffect(() => {
    var is_location_active = localStorage.getItem("is_active");
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }
    const permissonData = JSON.parse(localStorage.getItem("permissonData"));

    if (permissonData) {
      if (
        permissonData.access_reports === 0 ||
        permissonData.access_to_online_dashboard === 0
      ) {
        setAccessPage(0);
        return;
      }
    }

    getReportFilterData();
  }, []);

  /*
  @function get filtered report data
*/
  const getReportFilterData = () => {
    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_report_filter_data", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("***************");
        console.log(response.data);
        console.log("***************");
        setEmpDropDownVal(response.data.emp);

        var deviceArr = response.data.devices;
        if (response.data.processor == "TSYS TransIT") {
          deviceArr.push({
            is_approve: "1",
            serial_number: "Virtual Terminal",
            nick_name: "",
          });
          deviceArr.push({
            is_approve: "1",
            serial_number: "Payment Link",
            nick_name: "",
          });
        }
        setDevicesDropDownVal(deviceArr);
        setReportStartDate(response.data.report_start_time.split(":"));
      })
      .catch((error) => {
        return error;
      });
  };

  /*
  @function get report data
*/
  const getReportData = (data) => {
    setTableData(false);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "merchant_report_sales_datewise", data, {
        headers: headers,
      })
      .then((response) => {
        setTableData(response.data.tableData);
        setGrossTotal(response.data.grossSalesTotal);
        setDiscountTotal(response.data.discountTotal);
        setRefundTotal(response.data.refundTotal);
        setTaxesTotal(response.data.taxesTotal);
        setTipTotal(response.data.tipTotal);
        setServiceChargesTotal(response.data.serviceChargesTotal);
        setCollectedAmtTotal(response.data.collectedAmtTotal);
        setNetSaleTotal(response.data.netSaleTotal);
        setTotalOrder(response.data.totalOrder);
        setSalesType(response.data.salesType);
        setShowData(response.data.dateRange);

        var csvInsertData = [];
        csvInsertData.push(["Sales Overview Report"]);
        csvInsertData.push([response.data.dateRange]);
        // csvInsertData.push(["Filters:"]);
        csvInsertData.push([""]);
        csvInsertData.push(["Gross Sales", response.data.grossSalesTotal]);
        csvInsertData.push(["Net Sales", response.data.netSaleTotal]);
        csvInsertData.push([
          "Amount Collected",
          response.data.collectedAmtTotal,
        ]);
        csvInsertData.push(["Orders", response.data.totalOrder]);
        csvInsertData.push([""]);
        csvInsertData.push([
          "date",
          "gross sale",
          "discount",
          "refund",
          "net sale",
          "taxes",
          "tip",
          "service charges",
          "collected amt",
        ]);
        response.data.tableData.map((eachRow, id) => {
          csvInsertData.push([
            eachRow.date,
            eachRow.gross_sale,
            eachRow.discount > 0 ? "-" + eachRow.discount : eachRow.discount,
            eachRow.refund > 0 ? "-" + eachRow.refund : eachRow.refund,
            eachRow.net_sale,
            eachRow.taxes,
            eachRow.tip,
            eachRow.service_charges,
            eachRow.collected_amt,
          ]);
        });
        csvInsertData.push(["", "", "", "", "", "", "", "", ""]);
        csvInsertData.push([
          "TOTAL:-",
          response.data.grossSalesTotal,
          response.data.discountTotal > 0
            ? "-" + response.data.discountTotal
            : response.data.discountTotal,
          response.data.refundTotal > 0
            ? "-" + response.data.refundTotal
            : response.data.refundTotal,
          response.data.netSaleTotal,
          response.data.taxesTotal,
          response.data.tipTotal,
          response.data.serviceChargesTotal,
          response.data.collectedAmtTotal,
        ]);

        setCsvData(csvInsertData);
        var chartLabel = [];
        var chartLabelValue = [];
        var max = 0;
        response.data.tableData.map((eachRow, id) => {
          let label = eachRow.date.split(", ");
          let labelVal = eachRow.net_sale.replace(",", "");
          chartLabel.push(label[1]);
          chartLabelValue.push({ meta: "value is:", value: labelVal });
          max < labelVal ? (max = labelVal) : "";
        });
        var chart = {};
        emailsSubscriptionChart.options.high = 20;
        chart["data"] = { labels: chartLabel, series: [chartLabelValue] };
        chart["options"] = {
          axisX: {
            showGrid: true,
            labelInterpolationFnc: function (values, index) {
              return index % 2 === 0 ? values : null;
            },
          },
          axisY: {
            showGrid: true,
          },
          low: 0,
          high: max,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
          height: 400,
          plugins: [
            ChartistTooltip({
              appendToBody: true,
            }),
          ],
        };
        setChartData(chart);
      })
      .catch((error) => {
        return error;
      });
  };

  /*
  @function Employee filtered data
*/
  const employeeChange = (event) => {
    setEmployee(event.target.value);
    let filterData = reportFilter;
    filterData["emp"] = event.target.value;
    setReportFilter(filterData);
  };

  /*
    @function Device filtered data
  */
  const deviceChange = (event) => {
    setDevice(event.target.value);
    let filterData = reportFilter;
    filterData["device"] = event.target.value;
    setReportFilter(filterData);
  };

  /*
    @function date filtered data
  */
  const dateChange = (event) => {
    let selected_date_range = event.target.value;

    switch (selected_date_range) {
      case 1: //Get current day data
        setStartDate(
          new Date().setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        setEndDate(new Date());
        break;
      case 2: //Get yesterday data
        let Yesterday_date = new Date();
        Yesterday_date.setDate(Yesterday_date.getDate() - 1);
        setStartDate(
          Yesterday_date.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        Yesterday_date.setHours(
          23 + parseInt(reportStartDate[0]),
          59 + parseInt(reportStartDate[1]),
          59,
          59
        );
        setEndDate(Yesterday_date);
        break;
      case 3: //Get current week data
        let this_week = new Date();
        this_week.setDate(this_week.getDate() - this_week.getDay());
        setEndDate(new Date());
        setStartDate(
          this_week.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 4: //Get last week data
        let last_week_start_date = new Date();
        let last_week_end_date = new Date();
        last_week_start_date.setDate(
          last_week_start_date.getDate() - last_week_start_date.getDay() - 7
        );
        last_week_start_date.setHours(
          reportStartDate[0],
          reportStartDate[1],
          reportStartDate[2],
          0
        );
        last_week_end_date.setDate(
          last_week_end_date.getDate() - last_week_end_date.getDay() - 1
        );
        last_week_end_date.setHours(
          23 + parseInt(reportStartDate[0]),
          59 + parseInt(reportStartDate[1]),
          59,
          59
        );
        setEndDate(last_week_end_date);
        setStartDate(last_week_start_date);
        break;
      case 5: //Get last 7 days data
        let last_7_day = new Date();
        last_7_day.setDate(last_7_day.getDate() - 6);
        setEndDate(new Date());
        setStartDate(
          last_7_day.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 6: //Get current month data
        let this_month = new Date();
        var firstDay = new Date(
          this_month.getFullYear(),
          this_month.getMonth(),
          1
        );
        var lastDay = new Date(
          this_month.getFullYear(),
          this_month.getMonth() + 1,
          0
        );

        setEndDate(new Date());
        setStartDate(
          new Date(
            this_month.getFullYear(),
            this_month.getMonth(),
            1,
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 7: //Get last month data
        let last_month = new Date();
        setEndDate(
          new Date(
            last_month.getFullYear(),
            last_month.getMonth(),
            0,
            23 + parseInt(reportStartDate[0]),
            59 + parseInt(reportStartDate[1]),
            59,
            59
          )
        );
        setStartDate(
          new Date(
            last_month.getFullYear(),
            last_month.getMonth() - 1,
            1,
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 8: //Get last 30 days data
        let last_30_day = new Date();
        last_30_day.setDate(last_30_day.getDate() - 29);
        setEndDate(new Date());
        setStartDate(
          last_30_day.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 9: //Get last 3 months data
        let last_3_month = new Date();
        last_3_month.setMonth(last_3_month.getMonth() - 3);
        setEndDate(new Date());
        setStartDate(
          last_3_month.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 10: //Get this year data
        let this_year = new Date();
        this_year = new Date(this_year.getFullYear(), 0, 1);
        setStartDate(
          this_year.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        setEndDate(new Date());
        break;
      case 11: //Get last year data
        let lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
        setEndDate(
          new Date(
            lastyear.getFullYear(),
            11,
            31,
            23 + parseInt(reportStartDate[0]),
            59 + parseInt(reportStartDate[1]),
            59,
            59
          )
        );
        setStartDate(
          new Date(
            lastyear.getFullYear(),
            0,
            1,
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 12:
        setEndDate("");
        setStartDate("");
        break;
      default:
        break;
    }
    setDateRange(event.target.value);
  };

  /*
    @function For handle search using date and time
  */
  const handleSearch = () => {
    const data = {
      start_date: moment(startDate).format("YYYY-MM-DD HH:mm:00"),
      end_date: moment(endDate).format("YYYY-MM-DD HH:mm:59"),
      filterData: reportFilter,
    };
    getReportData(data);
  };

  /*
    @Const generate sales summary chart
  */
  const chartSalesSummary = {
    data: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May"],
      series: [
        [
          { meta: "value is:", value: 500 },
          { meta: "value is:", value: 200 },
          { meta: "value is:", value: 600 },
          { meta: "value is:", value: 400 },
          { meta: "value is:", value: 1000 },
        ],
        [
          { meta: "value is:", value: 200 },
          { meta: "value is:", value: 400 },
          { meta: "value is:", value: 800 },
          { meta: "value is:", value: 500 },
          { meta: "value is:", value: 600 },
        ],
      ],
    },
    options: {
      seriesBarDistance: 40,
      axisX: {
        showGrid: true,
      },
      axisY: {
        showGrid: true,
      },
      low: 0,
      high: 1000,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0,
      },
      height: 300,
      plugins: [
        ChartistTooltip({
          appendToBody: true,
        }),
      ],
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }

        if (data.type === "bar") {
          data.element.attr({
            style: "stroke: #235586; fill:#235586; stroke-width: 20px;",
          });
        }
      },
    },
  };
  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>
                Reporting - Sales Report
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>
                    <div>You do not have permission to access this page.</div>{" "}
                    <div>Please contact an administrator.</div>
                    <div>Thank you.</div>
                  </h3>
                </center>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  if (!tableData) {
    //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>
                Reporting - Sales Report
              </h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Fetching Data....</h3>
                <CircularProgress id="loginProgress"></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  var widthSetCol = "125px";
  var widthSetColMinWidth = "125px";
  var widthSetScroll = "scroll";
  var scrollAreaWidth = "max-content";
  if (tableData.length <= 8) {
    //Check total column was less then or equal to 8
    widthSetCol = 100 / tableData.length + "%";
    scrollAreaWidth = "100%";
    widthSetColMinWidth = 100 / tableData.length + "%";
    widthSetScroll = "auto";
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Reporting - Sales Report</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            {/*
             *Filter options dropdown
             */}
            <GridContainer>
              <GridItem
                id="gidaterange"
                xs={12}
                sm={12}
                md={4}
                style={{ marginTop: "16px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Date Range
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={dateRange}
                    label="Date Range"
                    onChange={dateChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Today</MenuItem>
                    <MenuItem value={2}>Yesterday</MenuItem>
                    <MenuItem value={3}>This Week</MenuItem>
                    <MenuItem value={4}>Last Week</MenuItem>
                    <MenuItem value={5}>Last 7 Days</MenuItem>
                    <MenuItem value={6}>This Month</MenuItem>
                    <MenuItem value={7}>Last Month</MenuItem>
                    <MenuItem value={8}>Last 30 Days</MenuItem>
                    <MenuItem value={9}>Last 3 Months</MenuItem>
                    <MenuItem value={10}>This Year</MenuItem>
                    <MenuItem value={11}>Last Year</MenuItem>
                    <MenuItem value={12}>Custom Date Range</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem
                id="giemployee"
                xs={12}
                sm={12}
                md={4}
                style={{ marginTop: "16px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Employees
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectEmployee}
                    label="Employees"
                    onChange={employeeChange}
                  >
                    <MenuItem value={0}>All Employees</MenuItem>
                    {empDropDownVal.map((eachEmp, id) => {
                      return (
                        <MenuItem value={eachEmp.user_id}>
                          {eachEmp.employee_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem
                id="gidevice"
                xs={12}
                sm={12}
                md={4}
                style={{ marginTop: "16px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Sources
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="chart"
                    value={selectDevice}
                    label="Sources"
                    onChange={deviceChange}
                  >
                    <MenuItem value={0}>All Sources</MenuItem>
                    {devicesDropDownVal.map((eachDevice, id) => {
                      var val =
                      eachDevice.serial_number +
                      "(" +
                      eachDevice.nick_name +
                      ")";
                      if(eachDevice.serial_number == "Payment Link" || eachDevice.serial_number == "Virtual Terminal")
                      {
                         val = eachDevice.serial_number;
                      }
                      return (
                        <MenuItem 
                        key={eachDevice.serial_number}
                        value={eachDevice.serial_number}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="End Date"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <Button
                    id="btnsearch"
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    className={`${classes.button} ${classes.buttonSearch}`}
                  >
                    Search
                  </Button>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br></br>
            <br></br>

            <div style={{ display: tableData.length > 0 ? "" : "none" }}>
              <GridContainer>
                <div
                  style={{ width: "100%", float: "right", textAlign: "right" }}
                >
                  {
                    <div style={{ display: "flex", float: "left" }}>
                      <CSVLink data={csvData} color="primary">
                        <Button
                          id="btnGreenAdd"
                          className={classes.button}
                          style={{
                            marginTop: "25px",
                            backgroundColor: weeklyBtnColor
                              ? "#ffa726"
                              : "#FFFFFF",
                            color: weeklyBtnColor ? "#FFFFFF" : "#ffa726",
                            border: weeklyBtnColor
                              ? "1px solid #ffa726"
                              : "1px solid #ffa726",
                            boxShadow: "none",
                            borderRadius: "0px",
                          }}
                        >
                          Export
                        </Button>
                      </CSVLink>
                    </div>
                  }
                  <div style={{ display: "flex", float: "right" }}>
                    <Button
                      onClick={() => {
                        setVisibleSummary(false);
                        setVisible(true);
                        setWeeklyBtnColor(true);
                        setSummaryBtnColor(false);
                      }}
                      id="btnWeeklySales"
                      variant="contained"
                      color="primary"
                      style={{
                        marginTop: "25px",
                        backgroundColor: weeklyBtnColor ? "#ffa726" : "#FFFFFF",
                        color: weeklyBtnColor ? "#FFFFFF" : "#ffa726",
                        border: weeklyBtnColor
                          ? "1px solid #ffa726"
                          : "1px solid #ffa726",
                        boxShadow: "none",
                        borderRadius: "0px",
                      }}
                      className={classes.button}
                    >
                      WEEKLY SALES
                    </Button>
                    <Button
                      onClick={() => {
                        setVisible(false);
                        setVisibleSummary(true);
                        setWeeklyBtnColor(false);
                        setSummaryBtnColor(true);
                      }}
                      id="btnSummarySales"
                      variant="contained"
                      color="primary"
                      style={{
                        marginTop: "25px",
                        marginRight: "10px",
                        backgroundColor: summaryBtnColor
                          ? "#ffa726"
                          : "#FFFFFF",
                        color: summaryBtnColor ? "#FFFFFF" : "#ffa726",
                        border: summaryBtnColor
                          ? "1px solid #ffa726"
                          : "1px solid #ffa726",
                        boxShadow: "none",
                        borderRadius: "0px",
                      }}
                      className={classes.button}
                    >
                      SUMMARY
                    </Button>
                  </div>
                </div>
                <div
                  class="divOtherTotalData"
                  style={{
                    width: "100%",
                    height: "100px",
                    paddingTop: "25px",
                    backgroundColor: "#f2fcee",
                    marginTop: "20px",
                    display: "flex",
                  }}
                >
                  <div
                    class="divgrosssales"
                    style={{
                      width: "25%",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingTop: "5px",
                        fontSize: "1.4rem",
                      }}
                    >
                      Gross Sales
                    </div>
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontSize: "1.75em",
                        paddingTop: "10px",
                      }}
                    >
                      ${grossTotal}
                    </div>
                  </div>
                  <div
                    class="divnetsales"
                    style={{
                      width: "25%",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingTop: "5px",
                        fontSize: "1.4rem",
                      }}
                    >
                      Net Sales
                    </div>
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontSize: "1.75em",
                        paddingTop: "10px",
                      }}
                    >
                      ${netSaleTotal}
                    </div>
                  </div>
                  <div
                    class="divamt"
                    style={{
                      width: "25%",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingTop: "5px",
                        fontSize: "1.4rem",
                      }}
                    >
                      Amount Collected
                    </div>
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontSize: "1.75em",
                        paddingTop: "10px",
                      }}
                    >
                      ${collectedAmtTotal}
                    </div>
                  </div>
                  <div
                    class="divorders"
                    style={{
                      width: "25%",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingTop: "5px",
                        fontSize: "1.4rem",
                      }}
                    >
                      Orders
                    </div>
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontSize: "1.75em",
                        paddingTop: "10px",
                      }}
                    >
                      {totalOrders}
                    </div>
                  </div>
                </div>

                {/*
                 *Display sales report chart
                 */}
                <div
                  class="sales-overview"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <ChartistGraph
                    className="ct-chart"
                    data={chartData.data}
                    type="Bar"
                    options={chartData.options}
                    responsiveOptions={
                      emailsSubscriptionChart.responsiveOptions
                    }
                    listener={emailsSubscriptionChart.animation}
                  />
                </div>

                {/*
                 * Display weekly sales report
                 */}
                <div
                  style={{ display: visible ? "block" : "none", width: "100%" }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "20px",
                    }}
                  >
                    Weekly Sales
                  </div>
                  <div
                    className="container reportsalestable"
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        border: "1px solid #DADADA",
                        borderBottom: "none",
                      }}
                    >
                      <div
                        class="divSalesReportLeftHdr"
                        style={{
                          width: "20%",
                          float: "left",
                          borderRight: "1px solid #CACACA",
                        }}
                      >
                        <div class="divtblleftheader">
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.rowBackgroundColorOne} ${classes.reportRowLeft} ${classes.borderBottomLine}`}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {" "}
                            {moment(startDate).format("DD-MMM-YYYY")} -{" "}
                            {moment(endDate).format("DD-MMM-YYYY")}
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.reportRowLeft} ${classes.fontWeightBold}`}
                          >
                            Gross Sales
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.rowBackgroundColorOne} ${classes.reportRowLeft}`}
                          >
                            Discounts
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.reportRowLeft}`}
                          >
                            Refunds
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.rowBackgroundColorOne} ${classes.reportRowLeft} ${classes.fontWeightBold}`}
                          >
                            Net Sales
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.reportRowLeft}`}
                          >
                            Non-revenue Items
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.rowBackgroundColorOne}  ${classes.reportRowLeft}`}
                          >
                            Gift Card Activations
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.reportRowLeft}`}
                          >
                            Taxes & Fees
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.rowBackgroundColorOne}  ${classes.reportRowLeft}`}
                          >
                            Tips
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.reportRowLeft}`}
                          >
                            Non-Cash Adjustment
                          </div>
                          <div
                            class="tblleftheader"
                            className={`${classes.reportEachRow} ${classes.rowBackgroundColorOne}  ${classes.reportRowLeft} ${classes.fontWeightBold} ${classes.borderTopLine}`}
                          >
                            Amount Collected
                          </div>
                          <div
                            class="tblleftheader"
                            style={{
                              borderBottom: "1px solid rgb(218, 218, 218)",
                            }}
                            className={`${classes.reportEachRow} ${classes.reportRowLeft}`}
                          >
                            Unpaid Balance
                          </div>
                        </div>
                      </div>
                      <div
                        class="divSalesReportTblData"
                        style={{ width: "60%", float: "left" }}
                      >
                        <div
                          style={{ width: "100%", overflowX: widthSetScroll }}
                        >
                          <div style={{ width: scrollAreaWidth }}>
                            {tableData.map((eachRow, id) => {
                              //Fetch all table data
                              return (
                                <div
                                  className={`${classes.reportEachRowScrollable}`}
                                  style={{
                                    width: widthSetCol,
                                    minWidth: widthSetColMinWidth,
                                  }}
                                >
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.borderBottomLine}`}
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {eachRow.date}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.classTextRight} ${classes.fontWeightBold}`}
                                  >
                                    ${eachRow.gross_sale}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.classTextRight}`}
                                    style={{
                                      color: eachRow.discount > 0 ? "red" : "",
                                    }}
                                  >
                                    {eachRow.discount > 0 ? "-" : ""}$
                                    {eachRow.discount}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.classTextRight}`}
                                    style={{
                                      color: eachRow.refund > 0 ? "red" : "",
                                    }}
                                  >
                                    {eachRow.refund > 0 ? "-" : ""}$
                                    {eachRow.refund}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.classTextRight} ${classes.fontWeightBold}`}
                                  >
                                    ${eachRow.net_sale}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.classTextRight}`}
                                  >
                                    $0.00
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.classTextRight}`}
                                  >
                                    $0.00
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.classTextRight}`}
                                  >
                                    ${eachRow.taxes}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.classTextRight}`}
                                  >
                                    ${eachRow.tip}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.classTextRight}`}
                                  >
                                    ${eachRow.service_charges}
                                  </div>
                                  <div
                                    className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.classTextRight} ${classes.fontWeightBold}  ${classes.borderTopLine}`}
                                  >
                                    ${eachRow.collected_amt}
                                  </div>
                                  <div
                                    style={{
                                      borderBottom:
                                        "1px solid rgb(218, 218, 218)",
                                      marginBottom: "5px",
                                    }}
                                    className={`${classes.reportEachRowRightSide} ${classes.classTextRight}`}
                                  >
                                    $0.00
                                  </div>
                                </div>
                              );
                            })}
                            <div style={{ clear: "both" }}></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="divSalesReportRightTotal"
                        style={{
                          width: "19%",
                          float: "left",
                          paddingRight: "",
                          borderLeft: "1px solid #CACACA",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              paddingRight: "10px",
                              textAlign: "center",
                            }}
                            className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.reportRowRight} ${classes.borderBottomLine}`}
                          >
                            Total
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.reportRowRight} ${classes.fontWeightBold} ${classes.fontBlueColor}`}
                          >
                            ${grossTotal}
                          </div>
                          <div
                            className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.reportRowRight}`}
                            style={{
                              paddingRight: "10px",
                              color: discountTotal > 0 ? "red" : "",
                            }}
                          >
                            {discountTotal > 0 ? "-" : ""}${discountTotal}
                          </div>
                          <div
                            className={`${classes.reportEachRowRightSide} ${classes.reportRowRight}`}
                            style={{
                              paddingRight: "10px",
                              color: refundTotal > 0 ? "red" : "",
                            }}
                          >
                            {refundTotal > 0 ? "-" : ""}${refundTotal}
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.reportRowRight} ${classes.fontWeightBold}  ${classes.fontBlueColor}`}
                          >
                            ${netSaleTotal}
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.reportRowRight}`}
                          >
                            $0.00
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.reportRowRight}`}
                          >
                            $0.00
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.reportRowRight}`}
                          >
                            ${taxesTotal}
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.reportRowRight}`}
                          >
                            ${tipTotal}
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.reportRowRight}`}
                          >
                            ${serviceChargesTotal}
                          </div>
                          <div
                            style={{ paddingRight: "10px" }}
                            className={`${classes.reportEachRowRightSide} ${classes.rowBackgroundColorOne} ${classes.reportRowRight} ${classes.fontWeightBold}  ${classes.borderTopLine}`}
                          >
                            ${collectedAmtTotal}
                          </div>
                          <div
                            style={{
                              paddingRight: "10px",
                              borderBottom: "1px solid rgb(218, 218, 218)",
                            }}
                            className={`${classes.reportEachRowRightSide} ${classes.reportRowRight}`}
                          >
                            $0.00
                          </div>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </GridContainer>

              {/*
               * display sales summary report
               */}
              <GridContainer>
                <div
                  className="divSummary"
                  style={{
                    display: visibleSummary ? "flex" : "none",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <table
                    style={{ width: "100%", borderCollapse: "collapse" }}
                    className={classes.tableSummary}
                  >
                    <tr>
                      <th
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          paddingBottom: "30px",
                        }}
                      >
                        Sales Summary
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                        }}
                      >
                        <b>Gross Sales</b>
                      </td>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingRight: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                          color: "#3271ab",
                        }}
                      >
                        <b>${grossTotal}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          padding: "8px",
                        }}
                      >
                        Discounts
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          padding: "8px",
                          color: discountTotal > 0 ? "red" : "",
                        }}
                      >
                        {discountTotal > 0 ? "-" : ""}${discountTotal}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                        }}
                      >
                        Refunds
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                          color: refundTotal > 0 ? "red" : "",
                        }}
                      >
                        {refundTotal > 0 ? "-" : ""}${refundTotal}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          padding: "8px",
                        }}
                      >
                        <b>Net Sales</b>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          padding: "8px",
                          color: "#3271ab",
                        }}
                      >
                        <b>${netSaleTotal}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                        }}
                      >
                        Non-revenue Items
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                        }}
                      >
                        $0.00
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          padding: "8px",
                        }}
                      >
                        Gift Card Activations
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          padding: "8px",
                        }}
                      >
                        $0.00
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                        }}
                      >
                        Taxes & Fees
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          backgroundColor: "#f2f2f2",
                          padding: "8px",
                        }}
                      >
                        ${taxesTotal}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          padding: "8px",
                        }}
                      >
                        Service Charges
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          padding: "8px",
                        }}
                      >
                        ${serviceChargesTotal}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Unpaid Balance
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        $0.00
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid gray" }}>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "30px",
                          padding: "8px",
                        }}
                      >
                        <b>Amount Collected</b>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "30px",
                          padding: "8px",
                        }}
                      >
                        <b>${collectedAmtTotal}</b>
                      </td>
                    </tr>
                  </table>
                </div>
              </GridContainer>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
