/*
  @page Category list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';       
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';


function CustomToolbar() {
  return (
    <GridToolbarContainer>      
       <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }}  />  */}
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarFilterButton /> */}

    </GridToolbarContainer>
  );
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

/*
  @function category list page logic
*/
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [catId, setCatId] = useState("");
  const [catDeleteDailog, setCatDeleteDailog] = useState(false);
  const [accessPage, setAccessPage] = useState(1);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  /*
    @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function Get category list
  */
  const getCategoryList = () => {
    const data = {};
    const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('merchantToken') }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_add_form_detail', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.catData.forEach((eachCategory) => {

        let itemCategory = {}
        itemCategory["Id"] = eachCategory.id;
        itemCategory["Category_Name"] = eachCategory.name;
        itemCategory["Edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachCategory.id, eachCategory.name)}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        itemCategory["Delete"] = <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setCatId(eachCategory.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([eachCategory.id, eachCategory.name, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachCategory.id, eachCategory.name)}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setCatId(eachCategory.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(itemCategory);
      });
      setTableData(userArr);
    }).catch(error => {
      return error;
    });
  }
  const columns = [
    {
      field: 'Id', headerName: 'Id', flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Category_Name',
      headerName: 'Category Name',
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Edit',
      headerName: 'Edit',
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      flex: 1,
      renderCell: (params) => params.value
    },
  ];


  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissonData) {
      if (permissonData.access_item_catalog === 0 || permissonData.access_to_online_dashboard === 0) {
        setAccessPage(0);
        return;
      }
    }

    getCategoryList();
  }, []);


  /*
    @function to set catid and catname for edit category
  */
  const handleEditCat = (id, cat_name) => {
    setCatId(id);
    setCatName(cat_name);
    setOpenAddCategory(true);
  }

  /*
    @function For delete category
  */
  const handleDeleteCat = () => {
    setTableData(false);
    setCatDeleteDailog(false);
    const data = { id: catId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_item_cat', data, {
      headers: headers
    }).then(response => {
      getCategoryList();
      setCatId(0);
      if (response.data == 1) {
        setSuccess(true);
        setSuccessMsg("Category Deleted Successfully!");
      } else {
        setError(true);
        setErrorMsg("Some Error Occur!!");
      }
    }).catch(error => {
      getCategoryList();
      setCatId(0);
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });
  }

  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [catName, setCatName] = React.useState("");

  const handleCatNameChange = (event) => {
    const result = event.target.value.replace(/[^a-z1-9 ]/gi, '');
    setCatName(result)
  }


  /*
    @function For save category
  */
  const saveCategory = (id, category_name) => {
    setOpenAddCategory(false);
    setTableData(false);
    const data = { id: id, name: category_name };
    console.log(data);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_item_cat', data, {
      headers: headers
    }).then(response => {

      setCatId(0);
      setCatName("");
      getCategoryList();
      console.log(response)
      if (response.data.Error == 1) {
        setError(true);
        setErrorMsg("Duplicate category not accepted");
      } else {
        if (id == 0) {
          setSuccess(true);
          setSuccessMsg("Category Added Successfully!");
        }
        else {
          setSuccess(true);
          setSuccessMsg("Category Updated Successfully!");
        }
      }

    }).catch(error => {
      console.log(error);
      getCategoryList();
      setCatId(0);
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });

  }


  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Category List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>

                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Category List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Category...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>

      {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>


      {/* 
        Display success message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Category List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { setOpenAddCategory(true) }}
            >+</Button>

            {/* 
              *Display category list
            */}

            <DataGrid
            style={{marginTop:"20px"}}
              getRowId={(row) => row.Id}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              slots={{ toolbar: CustomToolbar }}
              rows={tableData}
              columns={columns}
              
              disableRowSelectionOnClick

            />

            {/* <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Category Name", "Edit", "Delete"]}
              tableData={tableData}
            /> */}
          </CardBody>
        </Card>

        {/* Add category popup start */}
        <Dialog open={openAddCategory} onClose={() => { setOpenAddCategory(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(catId == 0) ? "Add New " : "Update "}Category</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Category Name"
              type="email"
              onChange={handleCatNameChange}
              value={catName}
              fullWidth

            // setCatName(e.target.value)
            />
          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setCatId(0); setCatName(""); setOpenAddCategory(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenUpdate" onClick={() => { saveCategory(catId, catName) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(catId == 0) ? "Add " : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add category popup end */}

        {/* Delete category popup start*/}
        <Dialog
          open={catDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete category?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setCatId(0); setCatDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteCat} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete ctegory  popup end*/}
      </GridItem>
    </GridContainer>
  );
}
