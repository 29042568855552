import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Merchant from "layouts/Merchant.js";
import Login from "layouts/Login.js";

import ForgotPassword from "merchantViews/ForgotPassword/ForgotPassword.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import "assets/css/media-query.css"
window.domainUrl = "https://api17.magicpaypos.com/";
window.apiUrl = window.domainUrl+"api/";    
window.tsepHandler = (eventType, event) =>{
  if( eventType == "TokenEvent")
         {                            
            window.eventData = event;              
            window.eventTypeData = eventType;                    
     }
      
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      
      <Route path="/login" component={Login} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/admin" component={Admin} />
      {/*<Route path="/rtl" component={RTL} />*/}
      <Route path="/" component={Merchant} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);