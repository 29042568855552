/*
  @page role
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';

/*
  @const Page styles
*/
const styles = {
  cardModifierWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function role page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setRoleTableData] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [id, setId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [openAddRole, setOpenAddRole] = React.useState(false);
  const [roleDeleteDailog, setRoleDeleteDailog] = useState(false);
  const handleClose = (event, reason) => {
  
  /*
    @function For close error popup 
  */
    if (reason === 'clickaway') {
      return;
  }
    setError(false);
    setSuccess(false);
  };
  const setEditVal = (editVal) => {
    setRoleName(editVal.role_name);
  }
  
  /*
    @function For toggle refund
  */
  const toggleRefund = (roleId, roleName, e) => {
    setRoleName(false);
    const data = { id: roleId, roleName: roleName, status: e.target.checked };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'edit_role', data, {
      headers: headers
    }).then(response => {
      setSuccess(true);
      setSuccessMsg("Role Updated Successfully!");
    }).catch(error => {
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });
  }
  
  /*
    @function For get role list 
  */
  const getRoleList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_role_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.roleData.forEach((eachRole) => {
        let roleDisplayName, refundState, access_employees, access_to_online_dashboard, void_a_transaction,
          access_close_batch_section, access_item_catalog, access_transaction_history,
          add_a_custom_item_to_an_order, access_settings_menu,access_device_manager,access_tax,access_discount,access_employee_role,access_tip,access_reports,access_batch_reports,access_quick_payment = "";
        if (eachRole.issue_a_refund == 1) { //Check if issue refund is equal to 1
          refundState = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "issue_a_refund", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          refundState = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "issue_a_refund", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }
        if (eachRole.access_employees == 1) { //Check if access employee is equal to 1
          access_employees = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_employees", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_employees = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_employees", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }
        if (eachRole.void_a_transaction == 1) { //Check if void transaction is equal to 1
          void_a_transaction = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "void_a_transaction", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;

        } else {
          void_a_transaction = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "void_a_transaction", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_close_batch_section == 1) { //Check if access close batch section is equal to 1
          access_close_batch_section = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_close_batch_section", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_close_batch_section = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_close_batch_section", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_item_catalog == 1) { //Check if access item catalog is equal to 1
          access_item_catalog = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_item_catalog", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_item_catalog = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_item_catalog", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.add_a_custom_item_to_an_order == 1) { //Check if add custom item to order is equal to 1
          add_a_custom_item_to_an_order = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "add_a_custom_item_to_an_order", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          add_a_custom_item_to_an_order = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "add_a_custom_item_to_an_order", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_settings_menu == 1) { //Check if access setting menu is equal to 1
          access_settings_menu = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_settings_menu", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_settings_menu = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_settings_menu", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_transaction_history == 1) { //Check if access transaction history is equal to 1
          access_transaction_history = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_transaction_history", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_transaction_history = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_transaction_history", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_to_online_dashboard == 1) { //Check if access to online dashboard is equal to 1
          access_to_online_dashboard = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_to_online_dashboard", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_to_online_dashboard = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_to_online_dashboard", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_device_manager == 1) { 
          access_device_manager = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_device_manager", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_device_manager = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_device_manager", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_tax == 1) { 
          access_tax = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_tax", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_tax = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_tax", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_discount == 1) { 
          access_discount = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_discount", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_discount = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_discount", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }
      
        if (eachRole.access_employee_role == 1) { 
          access_employee_role = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_employee_role", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_employee_role = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_employee_role", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }
       
        if (eachRole.access_tip == 1) { 
          access_tip = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_tip", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_tip = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_tip", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }
       
        if (eachRole.access_reports == 1) { 
          access_reports = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_reports", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_reports = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_reports", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_batch_reports == 1) { 
          access_batch_reports = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_batch_reports", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_batch_reports = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_batch_reports", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        if (eachRole.access_quick_payment == 1) { 
          access_quick_payment = <Switch
            defaultChecked
            onChange={(e) => toggleRefund(eachRole.id, "access_quick_payment", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        } else {
          access_quick_payment = <Switch
            onChange={(e) => toggleRefund(eachRole.id, "access_quick_payment", e)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />;
        }

        roleDisplayName = eachRole.role_name
        userArr.push(
          <div id="divRoleList">
          <div styles={{margin:50}}>&nbsp;</div>
          <div style={{ background:'red' }} >
            <div style={{ width: '50%', float: 'left' }}>
              <b style={{fontSize:'20px'}}>{roleDisplayName}</b>
            </div>
            <div style={{ width: '50%', float: 'right',textAlign:'end' }}>
              &nbsp;<button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={(e) => { setId(eachRole.id); setEditVal(eachRole); setOpenAddRole(true); }}><EditIcon style={{height:'20px'}}></EditIcon></button>
              &nbsp;<button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={(e) => { setId(eachRole.id); setRoleDeleteDailog(true); }} ><DeleteForeverIcon></DeleteForeverIcon></button>
            </div>
          </div>
        <div styles={{margin:50}}>&nbsp;</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{refundState}Issue a Refund</div >
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_employees}Access Employees</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_to_online_dashboard}Access to Online Dashboard</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{void_a_transaction}Void a Transaction</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_close_batch_section}Access Close Batch</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_batch_reports}Access Batch Report</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_item_catalog}Access Item Catalog</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_transaction_history}Access Transaction History</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{add_a_custom_item_to_an_order}Add Custom Item to an Order</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_settings_menu}Access Settings</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_device_manager}Access Device Maanger</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_tax}Access Tax </div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_discount}Access Discounts</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_employee_role}Access Employee Role</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_tip}Access Tips</div>
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_reports}Access Reports</div>          
          <div id="roleList" style={{ width: '30%', float: 'left' }}>{access_quick_payment}Access Quick Payment</div>

          <div style={{ clear: 'both' }}></div><div styles={{margin:50}}>&nbsp;</div> <Divider />
        </div>);
      });

      setRoleTableData(userArr);
      setWait(true);
    }).catch(error => {
      return error;
    });
  }
  const [accessPage, setAccessPage] = useState(1);   
  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));  
    
    console.log("aaaabbbbbbbbbcccccccccccdddddddd",localStorage.getItem('permissonData'));
    
    if(permissonData)
    {
      if(permissonData.access_employee_role === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }
    getRoleList();
  }, []);

 
  /*
    @function For save role 
  */
  const saveRole = (id) => {

    setOpenAddRole(false);
    setRoleTableData(false);
    const data = { id: id, roleName: "role_name", status: roleName };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'edit_role', data, {
      headers: headers
    }).then(response => {
      setId(0);
      setRoleName("");
      getRoleList();
      if (id == 0) {
        setSuccess(true);
        setSuccessMsg("Role Added Successfully!");
      }
      else {
        setSuccess(true);
        setSuccessMsg("Role Updated Successfully!");
      }

    }).catch(error => {
      setId(0);
      setRoleName("");
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });

  }
  
  /*
    @function For delete role 
  */
  const handleDeleteRole = () => {
    setRoleTableData(false);
    setRoleDeleteDailog(false);
    const data = { id: id };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'delete_role', data, {
      headers: headers
    }).then(response => {
      getRoleList();
      setId(0);
      if (response.data == 1) {
        setSuccess(true);
        setSuccessMsg("Role Deleted Successfully.");
      } else {
        setError(true);
        setErrorMsg("Some Error Occur!!");
      }
    }).catch(error => {
      getRoleList();
      setId(0);
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });
  }

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Roles</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check table data was not come than display loading dialog
    var mainData = <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning" >
            <h4 className={classes.cardTitleWhite}>Roles</h4>
            <p className={classes.cardModifierWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <center>
              <h3>Loading Roles...</h3>
              <CircularProgress id="loginProgress" ></CircularProgress>
            </center>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>;
  }
  return (
    <GridContainer>
        {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      
        {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Roles</h4>
            <p className={classes.cardModifierWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { setId(0); setRoleName(''); setOpenAddRole(true) }}
            >+</Button>
            {tableData}
          </CardBody>
        </Card>
        {/* 
          *Add Role form start 
        */}
        <Dialog open={openAddRole} onClose={() => { setOpenAddRole(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(id == 0) ? "Add New " : "Update "}Role</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="rolename"
              label="Role Name"
              onChange={(e) => setRoleName(e.target.value.replace(/[^a-z1-9 ]/gi, ''))}
              value={roleName}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setId(0); setRoleName(""); setOpenAddRole(false) }} color="primary">
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveRole(id); setRoleTableData(false); }} color="primary">
              {(id == 0) ? "Add " : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* 
          * Delete Role popup start
        */}
        <Dialog
          open={roleDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete role?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setId(0); setRoleDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteRole} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}