class Helper {
    // Simple function
    static viewNumberFormat = (number) => {
        return (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };
    static formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return phoneNumberString;
    };
    static formatIVUControlNumber = (ivuNumberString) => {
        var cleaned = ('' + ivuNumberString).replace(/\D/g, '');
        if(cleaned.length > 11){
            cleaned = cleaned.slice(0, 11);  
        }  
        var match = cleaned.match(/^(\d{7})(\d{4})$/);
        
        if (match) {
            return [match[1], '-', match[2]].join('');        
        }
        return null;
    };

    static currencyFormatWithDot = (e, id) => {
        console.log(e);
        var number = document.getElementById(id).value;
        number = number.replace("$", "");
        if(number === "")
        {
            return "";
        }
        number = number.replace("$", "").replace('.','');
        number = number/100;
        return "$" +number.toFixed(2);
    };
    
    static viewNumberFormatInput = (e, id) => {
        
        var number = document.getElementById(id).value;
        number = number.replace("$", "");
        console.log(number);
        // console.log(e.which);
        // var point = e.which;
        if (!number.includes(".")) {
            //not a fullstop
            if (number === "") {
                return "";
            }
            console.log("inffff" + number);
            // var n = parseFloat((number).replace(/\,/g, ''), 10);
            var n = parseFloat((number).replace(/\,/g, ''), 10);
            return "$" + n.toLocaleString();
        }
        return "$" + number;
    };
    static viewPercentageFormat = (number) => {
        return (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + "%";
    };

    static viewCurrencyFormat = (number) => {
        if(parseFloat(number)<0)
        {
            return "-$" + (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace("-","");
        }else
        {
            return "$" + (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        
    };
    static viewCurrencyFormatWithoutDollar = (number) => {
        if(parseFloat(number)<0)
        {
            return (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace("-","");
        }else
        {
            return (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        
    };
}

export default Helper;