/*eslint-disable*/
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import Link from '@material-ui/core/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import axios from 'axios';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const [selectedLocation, setSelectedLocation] = React.useState('');
  const [locationList, setLocationList] = React.useState([]);
  let location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [opneSettingSubMenu, setOpneSettingSubMenu] = React.useState(false);
  const [opneReportSubMenu, setReportSubMenu] = React.useState(false);
  const [permissonArr, setPermissonArr] = React.useState([]);
  const [parentIdArr, setParentArr] = React.useState([]);

  const empLocationChange = (event) => {
    let loc = event.target.value;
    setSelectedLocation(loc);
    localStorage.setItem('parent_id', parentIdArr[loc]);
    localStorage.setItem('location_id', loc);
    localStorage.setItem('role', 'merchant');        
    localStorage.setItem('permissonData', permissonArr[loc]);
    window.location.reload();
  };

  useEffect(() => {

  }, []);


  const handleClick = (e,menuName) => {
    e.preventDefault()
    if(menuName === "Settings")
    {     
      setOpneSettingSubMenu(!opneSettingSubMenu);
    }else if(menuName === "Item Catalog")
    {
        setOpen(!open);
    }
    else if(menuName === "Reports")
    {     
      setReportSubMenu(!opneReportSubMenu);
    }
    
  };

  const handleOtherClick = () => {
    setOpen(false);
  };



  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  var email = "";
  email = localStorage.getItem('admin_email');   

  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        var listItemClassesSub;
        //if (prop.path === "/upgrade-to-pro") 
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          if (prop.subMenuFlag !== "1") {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(((prop.layout == "/user") ? "" : prop.layout) + prop.path),
            });
          } else {
            listItemClasses = "";
          }

        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(((prop.layout == "/user") ? "" : prop.layout) + prop.path),
        });
        return (          
          <NavLink
            to={((prop.layout == "/user") ? "" : prop.layout) + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            onClick={(e) => { (prop.subMenuFlag === "1") ? e.preventDefault() : "" }}
            key={key}
          >
            
              
                <ListItem button className={classes.itemLink + listItemClasses} onClick={(e)=>(prop.subMenuFlag === "1") ? handleClick(e,prop.name) : handleOtherClick}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
                {prop.subMenuFlag === "1" && prop.name === "Item Catalog" ? (!open ? <ExpandMore className={classes.expandMoreCls} /> : <ExpandLess className={classes.expandLessCls} />) : ""}
                {prop.subMenuFlag === "1" && prop.name === "Settings" ? (!opneSettingSubMenu ? <ExpandMore className={classes.expandMoreCls} /> : <ExpandLess className={classes.expandLessCls} />) : ""}
                {prop.subMenuFlag === "1" && prop.name === "Reports" ? (!opneReportSubMenu ? <ExpandMore className={classes.expandMoreCls} /> : <ExpandLess className={classes.expandLessCls} />) : ""}


              </ListItem>
            
            
            {(prop.subMenuFlag === "1" && prop.name === "Item Catalog") ? (
              <Collapse style={{ marginLeft: 40 }} in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {prop.subMenu.map((subMenu, subKey) => {

                    listItemClassesSub = classNames({
                      [" " + classes[color]]: activeRoute(((prop.layout == "/user") ? "" : prop.layout) + subMenu.path),
                    });

                    return (
                      <NavLink
                        to={subMenu.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={"1" + subKey}
                      >
                        <ListItem button className={classes.itemLink + listItemClassesSub}>
                          {typeof subMenu.icon === "string" ? (
                            <Icon
                              className={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: subMenu.rtlActive,
                              })}
                            >
                              {subMenu.icon}
                            </Icon>
                          ) : (
                            <subMenu.icon
                              className={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: subMenu.rtlActive,
                              })}
                            />
                          )}
                          <ListItemText primary={subMenu.name} />
                        </ListItem>
                      </NavLink>
                    )
                  })}
                </List>
              </Collapse>
            ) : ""}

    {(prop.subMenuFlag === "1" && prop.name === "Settings") ? (
              <Collapse style={{ marginLeft: 40 }} in={opneSettingSubMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {prop.subMenu.map((subMenu, subKey) => {

                    listItemClassesSub = classNames({
                      [" " + classes[color]]: activeRoute(((prop.layout == "/user") ? "" : prop.layout) + subMenu.path),
                    });

                    return (
                      <NavLink
                        to={subMenu.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={"1" + subKey}
                      >
                        <ListItem button className={classes.itemLink + listItemClassesSub}>
                          {typeof subMenu.icon === "string" ? (
                            <Icon
                              className={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: subMenu.rtlActive,
                              })}
                            >
                              {subMenu.icon}
                            </Icon>
                          ) : (
                            <subMenu.icon
                              className={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: subMenu.rtlActive,
                              })}
                            />
                          )}
                          <ListItemText primary={subMenu.name} />
                        </ListItem>
                      </NavLink>
                    )
                  })}
                </List>
              </Collapse>
            ) : ""}


            {(prop.subMenuFlag === "1" && prop.name === "Reports") ? (
              <Collapse style={{ marginLeft: 40 }} in={opneReportSubMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {prop.subMenu.map((subMenu, subKey) => {

                    listItemClassesSub = classNames({
                      [" " + classes[color]]: activeRoute(((prop.layout == "/user") ? "" : prop.layout) + subMenu.path),
                    });

                    return (
                      <NavLink
                        to={subMenu.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={"1" + subKey}
                      >
                        <ListItem button className={classes.itemLink + listItemClassesSub}>
                          {typeof subMenu.icon === "string" ? (
                            <Icon
                              className={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: subMenu.rtlActive,
                              })}
                            >
                              {subMenu.icon}
                            </Icon>
                          ) : (
                            <subMenu.icon
                              className={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: subMenu.rtlActive,
                              })}
                            />
                          )}
                          <ListItemText primary={subMenu.name} />
                        </ListItem>
                      </NavLink>
                    )
                  })}
                </List>
              </Collapse>
            ) : ""}

          </NavLink>
        );
      })}
    </List>
  );
  var brand = (<div className={classes.logo}>
            <a              
              className={classNames(classes.logoLink, {
                [classes.logoLinkRTL]: props.rtlActive,
              })}
              target="_blank"
            >
              {/* <div className={classes.logoImage}>
              
              </div> */}
              <div className={classes.logoArea}>
                <img src={logo} alt="logo" style={{ "width": "100%" }} />
              </div>

            </a>
        </div>);
  if(Object.keys(locationList).length > 0)
  {
    brand = (
      <div className={classes.logo}>
  
            <GridContainer>
              <GridItem id="girange" xs={12} sm={12} md={4} style={{"max-width":"100%","flex-basis":"100%"}}>
                
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">Location </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedLocation}
                    label="Location"
                    onChange={empLocationChange}
                    >
                    {
                      Object.entries(locationList).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>
  
                      ))
                    }                  
                  </Select>
                </FormControl>
                </GridItem>
                </GridContainer>
  
        <a          
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive,
          })}
          target="_blank"
        >
          {/* <div className={classes.logoImage}>
          
          </div> */}
          <div className={classes.logoArea}>
            <img src={logo} alt="logo" style={{ "width": "100%" }} />
          </div>
  
        </a>
      </div>
    );
  }
  
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
        id="navMenuDrawerrightside"
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
            id="sidebarBackImagerightside"
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          id="navMenuDrawer"
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              id="sidebarBackImage"
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
