/*
   @page Main login
*/

//Import require libraries

import React, { useState,useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LogoPath from "assets/img/Logo.png";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

/*
   @function print copyright text at bottom
*/
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://magicpay.net/">
       MagicPay Merchant Services LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <br />
      <Link href="https://magicpay.net/terms/">Terms of Use</Link>
    </Typography>
  );
}

/*
   @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
   @const Page styles
*/
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


/*
   @function Login page logic
*/

export default function LoginPage() {
  
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loginError, setLoginError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
 
  const queryParams = new URLSearchParams(window.location.search);  
  console.log("d111111");
  localStorage.setItem('domain', "web14......");
  useEffect(() => {
    if(queryParams.get('arg1') !== null && queryParams.get('arg2') !== null && queryParams.get('arg3') !== null && queryParams.get('arg1') !== "" && queryParams.get('arg2') !== "" && queryParams.get('arg3') !== "")
    {      
      const data = { email: atob(queryParams.get('arg1')), password: atob(queryParams.get('arg2')),merchant_id: atob(queryParams.get('arg3'))};
      axios.post(window.apiUrl + 'login_admin_and_merchant', data)
        .then(response => {
          console.log("------------------");
              console.log(response);
              console.log("-------------------");
          document.getElementById("loginProgress").style.display = "none";
          
          if (response.data.error === "UnAuthorised") { //Check username & password valid or not
            alert("invalid user name and password");
          }
        
          localStorage.setItem('token', response.data.admin_token);
          localStorage.setItem('merchantToken', response.data.merchant_token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('id', response.data.id);
          localStorage.setItem('parent_id', response.data.parent_id);
          localStorage.setItem('location_id', response.data.location_id);            
          if(localStorage.getItem("merchantToken") !== null)
          {
            window.location.href = "/dashboard";
          }
          
    
        }).catch(error => {
          document.getElementById("loginProgress").style.display = "none";
          if (error.response.data.activateError) { 
            setErrorMsg(error.response.data.activateError);
          } else {
            setErrorMsg("Invalid eMail or Password!");
          }
          setLoginError(true);
          return error;
        });       
         
    }
   else if(queryParams.get('arg1') !== null && queryParams.get('arg2') !== null && queryParams.get('arg1') !== "" && queryParams.get('arg2') !== "")
    {
    var emailCheck;  	
    emailCheck = atob(queryParams.get('arg1'));   
    const data = { email: atob(queryParams.get('arg1')), password: atob(queryParams.get('arg2')) };
    axios.post(window.apiUrl + 'login', data)
      .then(response => {
        document.getElementById("loginProgress").style.display = "none";
        
        if (response.data.error === "UnAuthorised") { //Check username & password valid or not
          alert("invalid user name and password");
        }
        if(response.data.permission_data)
        {
          localStorage.setItem('permissonData', JSON.stringify(response.data.permission_data));
          
        }
        if (response.data.token) {
          setToken(response.data.token);
          if (response.data.role == "4") { //Check user was admin or merchant

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('group_id', response.data.group_id);
            if(localStorage.getItem("token") !== "token")
            {
              window.location.href = "/admin/dashboard";
            }
            
          } else {
            
            localStorage.setItem('merchantToken', response.data.token);
            localStorage.setItem('role', 'merchant');
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('parent_id', response.data.parent_id);
            localStorage.setItem('location_id', response.data.location_id);           
            if(localStorage.getItem("merchantToken") !== null)
            {
              window.location.href = "/dashboard";
            }
            
          }
  
        }
  
      }).catch(error => {
        document.getElementById("loginProgress").style.display = "none";
        if (error.response.data.activateError) { 
          setErrorMsg(error.response.data.activateError);
        } else {
          setErrorMsg("Invalid eMail or Password!");
        }
        setLoginError(true);
        return error;
      });
      
    }
    
  }, []);  
  

  /*
    @function form validation
    check email & password not blank
  */
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setLoginError(false);
  };

  /*
   @function Login form submit
  */
  function handleSubmit(event) {
    event.preventDefault();
    document.getElementById("loginProgress").style.display = "block";
    const data = { email: email, password: password };
    axios.post(window.apiUrl + 'login', data)
      .then(response => {
       
        document.getElementById("loginProgress").style.display = "none";
        
        if (response.data.error === "UnAuthorised") { //Check username & password valid or not
          alert("invalid user name and password");
        }
        if(response.data.permission_data)
        {
          localStorage.setItem('permissonData', JSON.stringify(response.data.permission_data));
          
        }
        if (response.data.token) {
          localStorage.setItem('admin_email',email);
          localStorage.setItem('is_active',response.data.is_active);
          setToken(response.data.token);
          
          const currentWebUrl =  window.location.href;  
          var localHostMode = "OFF";
          if(currentWebUrl == "https://web17.magicpaypos.com/" || currentWebUrl == "https://web17.magicpaypos.com" || currentWebUrl == "https://web17.magicpaypos.com/login"  || currentWebUrl == "https://web17.magicpaypos.com/login/"){
            localHostMode = "ON"; 
          } else {
            if(currentWebUrl == "http://localhost:3000/login"){
                localHostMode = "ON";
            } else if(currentWebUrl == "https://devapp.magicpaypos.com/login/" || currentWebUrl == "https://devapp.magicpaypos.com/login" || currentWebUrl == "https://devapp.magicpaypos.com/"  || currentWebUrl == "https://devapp.magicpaypos.com"){
                localHostMode = "ON";
            } else if(currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/login/" || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/login" || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/"  || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com"){
              localHostMode = "ON";
            } 
          }  
          

          
          

          
          const currentWebUrlArr = currentWebUrl.split(response.data.reactWebBaseUrl); 
          var prefixUrl;	
          prefixUrl = currentWebUrlArr[0]; 	
          prefixUrl = prefixUrl.replace("http://", "");	
          prefixUrl = prefixUrl.replace("https://", ""); 	
          prefixUrl = prefixUrl.replace("www.", ""); 

          if (response.data.role == "4") { //Check user was admin or merchant
            localStorage.setItem('group_id', response.data.group_id);
            localStorage.setItem('arg1', email);
            localStorage.setItem('arg2', password);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('role', response.data.role);
            if(localStorage.getItem("token") !== "token")	
            {	
              
              localStorage.setItem('admin_permission',JSON.stringify(response.data.admin_permission));                        

              if(localHostMode == "ON"){	
                window.location.href = "/admin/dashboard"; 	
              } else {	
                if(prefixUrl == response.data.adminWebVersion){	
                  window.location.href = "/admin/dashboard";	
                } else { 	
                  window.location.href = "https://"+response.data.adminWebVersion+response.data.reactWebBaseUrl+"/login/?arg1="+btoa(email)+"&arg2="+btoa(password);	
                }	
              }	
            }	
            	
          } else {
           
            if(localHostMode == "ON"){
                  localStorage.setItem('merchantToken', response.data.token);
                  localStorage.setItem('role', 'merchant');
                  localStorage.setItem('id', response.data.id);
                  localStorage.setItem('parent_id', response.data.parent_id);
                  localStorage.setItem('location_id', response.data.location_id);           
                  if(localStorage.getItem("merchantToken") !== null)
                  {
                    window.location.href = "/dashboard"; 
                  } 
            } else if(localHostMode == "OFF"){
              
            if(response.data.maxVersion == response.data.deviceVersion){
              if(currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login" || currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/"){
                localStorage.setItem('merchantToken', response.data.token);
                localStorage.setItem('role', 'merchant');
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('parent_id', response.data.parent_id);
                localStorage.setItem('location_id', response.data.location_id);           
                if(localStorage.getItem("merchantToken") !== null)
                {
                  window.location.href = "/dashboard";
                }   
              } else {
                window.location.href = "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/?arg1="+btoa(email)+"&arg2="+btoa(password); 
              }
              

            } else if((parseFloat(response.data.maxVersion) > parseFloat(response.data.deviceVersion))){ 
              if(currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login" || currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/"){
                localStorage.setItem('merchantToken', response.data.token);
                localStorage.setItem('role', 'merchant');
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('parent_id', response.data.parent_id);
                localStorage.setItem('location_id', response.data.location_id);           
                if(localStorage.getItem("merchantToken") !== null)
                {
                  window.location.href = "/dashboard";
                }   
              } else {
                window.location.href = "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/?arg1="+btoa(email)+"&arg2="+btoa(password); 
              }
              
            }   else {
              window.location.href = "https://"+response.data.reactWebBaseUrl+"/";  
            }
           }
          }

        }

      }).catch(error => {
        console.log("-----ERROR START-----");  
        console.log(error);  
        document.getElementById("loginProgress").style.display = "none";
        if (error.response.data.activateError) { 
         setErrorMsg(error.response.data.activateError);
        } else {
          setErrorMsg("Invalid eMail or Password!");
        }
        setLoginError(true);
        return error;
      });

  }
 
  return (
    
    <Container component="main" maxWidth="xs">
      
      <div style={{ display: (queryParams.get('arg1') !== null) ? "block" : "none" }}>
          <center>Please wait...</center>
      </div>
      <div style={{ display: (queryParams.get('arg1') !== null) ? "none" : "block" }}>
      <CssBaseline />
      {/* 
        Display error message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={loginError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        {/* Magicpay main logo */}
        <img src={LogoPath}></img>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <CircularProgress id="loginProgress" style={{ display: "none" }}></CircularProgress>

        {/**
           * Form for user login
       */}

        <form className={classes.form} onSubmit={handleSubmit}>
          {/* Email address textfield */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* password textfield */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* sign in button */}
          <div id="divLoginBtn">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="warning"
              className={classes.submit}
              disabled={!validateForm()}
            >
              Sign In
            </Button>
          </div>
          {/* forgot password link  */}
          <Grid container>
            <Grid item xs>
              <div id="forgotPasswordDiv">
                <Link id="forgotPassword" href="/forgot_password" variant="body2">
                  Forgot password?
                </Link>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      {/* copyright and term and conditions */}
      <div id="copyRightDiv">
      <Box mt={8}>
        <Copyright />
      </Box>
      </div>
      </div>
    </Container>
  );
}